@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 14px;
}

body {
  background: #eff3f8;
  overflow: hidden;
}

.card {
  background: #ffffff;
  border-radius: 10px;
}

html.dark body {
  background: #040d19;
}

html.dark .card {
  background: #071426;
}
