$primary: #eaaa34;
$secondary: #248afd;
$sRed: #ff4747;
$sGreen: #71c02b;
$sYellow: #fcf806;
$white: #ffffff;
$background: #f5f7ff;
$sLightYellow: #eaaa34;
$black: rgb(24, 24, 24);
$lightBlack: rgb(58, 58, 58);
