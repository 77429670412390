.receipt {
  .p-datatable .p-datatable-thead > tr > th {
    background: none;
    border-top: 1px dashed $black;
    border-bottom: 1px dashed $black;
    padding: 0.5rem 0.4rem !important;
    text-align: center !important;
    color: black !important;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    border: 0 !important;
    padding: 0 0.1rem !important;
    color: black !important;
    font-weight: 600 !important;
    text-align: center;
  }
  .p-datatable .p-column-header-content {
    justify-content: center;
  }
  .p-datatable .p-datatable-tbody > tr > .item-name {
    text-align: left;
  }
  .p-datatable .p-datatable-tbody > tr > .item-qty {
    text-align: center;
  }
  .p-datatable .p-datatable-tbody > tr > .item-qty-header {
    text-align: left !important;
  }
  .p-datatable .p-datatable-tbody > tr > .item-price,
  .p-datatable .p-datatable-tbody > tr > .item-total {
    text-align: right;
  }
  .p-datatable {
    border-bottom: 1px dashed $black;
  }
}
